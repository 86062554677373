import React from "react"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { graphql, Link } from "gatsby"
import Fade from "react-reveal/Fade"
import PlaceholderImage from "@images/placeholder-image.jpg"

const Blogs = ({ data, location }) => {
  // console.log(data)
  return (
    <Layout headerType="solid" location={location}>
      <SeoComponent
        title="Latest Posts"
        description={data.site.siteMetadata.description}
      />
      <div className="solid-header-page-spacer" />
      <div className="pb-10">
        <div className="container">
          <div className="section-heading text-center">
            <span>Blog</span>
            <h1>Latest Posts</h1>
          </div>

          <div className="row my-5">
            {data.allSanityPost.edges.map((blog) => (
              <div className="col-md-6 mb-5">
                <Fade>
                  <div className="blog-item-wrapper h-100">
                    <Link
                      to={blog.node.slug.current}
                      aria-label={blog.node.title}
                    >
                      <div className="blog-img">
                        <div
                          className="image-bg"
                          style={{
                            backgroundImage: `url(${
                              blog.node.mainImage &&
                              blog.node.mainImage.asset &&
                              blog.node.mainImage.asset.url
                                ? blog.node.mainImage.asset.url
                                : PlaceholderImage
                            })`,
                          }}
                        ></div>
                      </div>
                    </Link>
                    <span className="d-block text-uppercase my-3 small text-muted">
                      {blog.node.publishedAt}
                    </span>
                    <Link className="blog-title" to={blog.node.slug.current}>
                      <h2 className="h4 mb-3">{blog.node.title}</h2>
                    </Link>
                    <p>{blog.node.excerpt}</p>
                    <Link
                      className="read-more-link"
                      to={blog.node.slug.current}
                    >
                      Read More
                    </Link>
                  </div>
                </Fade>
              </div>
            ))}
          </div>
          <span className="d-block text-muted text-center font-italic">
            You've reached the end of blog posts.
          </span>
        </div>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              url
            }
          }
          excerpt
          slug {
            current
          }
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
      }
    }
  }
`
